import {
  ArrowDropDown,
  DeleteOutlineOutlined,
  ExpandMore,
  RocketLaunchOutlined,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  FormControl,
  Menu,
  MenuItem,
  Select,
  Slider,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import gpu from "../../assets/createAiCompute/gpu-icon.svg";
import cpu from "../../assets/createAiCompute/cpu-icon.svg";
import pythonImg from "../../assets/createAiCompute/python-icon.svg";
import jupyter from "../../assets/createAiCompute/jupter-icon.svg";
import vsCode from "../../assets/createAiCompute/vscode-icon.svg";
import gui from "../../assets/createAiCompute/gui-icon.svg";
import ubuntu from "../../assets/createAiCompute/ubuntu-icon.svg";
import window from "../../assets/createAiCompute/window-icon.svg";
import centOs from "../../assets/createAiCompute/cent-os-icon.svg";
import fedora from "../../assets/createAiCompute/fedora-icon.svg";
import nvidia from "../../assets/createAiCompute/nvidia-icon.svg";
import intel from "../../assets/createAiCompute/intel.svg";
import { TextField } from "@mui/material";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../api/axios";

function CreateAiComputeLeft() {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [selectedOption, setSelectedOption] = useState("gpu");
  const [selectComputeRes, setSelectComputeRes] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const open = Boolean(anchorEl);
  const [selectType, setSelectType] = useState(null);
  const [selectTypeRes, setSelectTypeRes] = useState(null);
  const [selectOs, setSelectOs] = useState(null);
  const [selectProgrammingLang, setSelectProgrammingLang] = useState(null);
  const [selectInterface, setSelectInterface] = useState(null);
  const [selectedAutoStop, setSelectedAutoStop] = useState(160);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [slectedRegion, setSlectedRegion] = useState([]);
  const navigate = useNavigate();
  const [launchingInstance, setLaunchingInstance] = useState(false);
  const [savedTempModal, setSavedTempModal] = useState(false);
  const gpuCountValues = selectTypeRes?.gpu_count;
  const vcpuValues = selectTypeRes?.vcpu_core;
  const ramValues = selectTypeRes?.ram;
  const storageValues = selectTypeRes?.default_storage;
  const qubridSelection = selectTypeRes?.qubrid_name;
  const gpuMemory = selectTypeRes?.gpu_memory;
  const pricing = selectTypeRes?.os_version;
  const region = selectTypeRes?.region;
  const pythonVersion = selectTypeRes?.os_version;
  const osVersion = selectTypeRes?.os_version;
  console.log(selectTypeRes?.os_version);
  console.log(osVersion);
  const handleRefreshToken = async () => {
    try {
      await axiosInstance.post("/user/refresh_token");
      return true;
    } catch (error) {
      console.log("refresh token");
      return false;
    }
  };

  // const [savedPricing, setSavedPricing] = useState('');
  const [savedTempLaunching, setSavedTempLaunching] = useState(false);
  const getSaveTempValue = async (item) => {
    setSavedTempLaunching(true);
    setSavedTempModal(false);

    setTimeout(() => {
      setSavedTempLaunching(false);
      navigate("/myInstance");
    }, 5000);

    const response = await axiosInstance.post(
      `/manage/create_launch-instance`,
      {
        instance_type: item?.family_type,
        qubrid_name: item?.qubrid_name,
        ubuntu_version: item?.ubuntu_version,
        auto_stop: item?.auto_stop,
      }
    );

    window.open(response.data.notebookUrl, "_blank");
    toast(response.data.message);
  };
  const [regionOpted, setRegionOpted] = useState(null);
  const handleRegions = async (event) => {
    setRegionOpted(event.target.value);
    const url = `/manage/families?type=${selectedOption}&region=${event.target.value}`;
    try {
      const response = await axiosInstance.get(url);
      const data = response.data;
      setSelectComputeRes(data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          handleOptionChange(event.target.value);
        }
      } else {
        console.error("Error:", error);
      }
    }
  };
  const handleOptionChange = async (event) => {
    setSelectedGpu({ index: "", value: "" });
    setSelectedVcpu({ index: "", value: "" });
    setSelectedRam({ index: "", value: "" });
    setSelectedStorage({ index: "", value: "" });
    setSelectedQubrid({ index: "", value: "" });
    setSelectedGpuMemory({ index: "", value: "" });
    setSelectedOsVersion({ index: "", value: "" });
    setSelectOs(null);
    setSelectedValue(null);
    setSelectType(null);
    setSelectTypeRes(null);
    setSelectProgrammingLang(null);
    setSelectInterface(null);
    setSelectComputeRes(null);
    setRegionOpted(null);
    setSelectedOption(event.target.value);

    const url = `/manage/families?type=${event.target.value}`;
    try {
      const response = await axiosInstance.get(url);
      const data = response.data;
      setSlectedRegion(data);
      setSelectComputeRes(data);
      console.log(data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          handleOptionChange(event.target.value);
        }
      } else {
        console.error("Error:", error);
      }
    }
  };

  useEffect(() => {
    const fetchFamilies = async () => {
      const url = `/manage/families?type=gpu`;
      try {
        const response = await axiosInstance.get(url);
        const data = response.data;
        setSlectedRegion(data);
        setSelectComputeRes(data);
      } catch (error) {
        console.log("Error fetching families:", error);
      }
    };

    fetchFamilies(); // Call the async function
  }, []); // Dependency array remains empty

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMenuItemClick = useCallback((value) => {
    setSelectedValue(value);
    handleClose();
  });

  const handleSelectTypeOptionChange = async (event) => {
    setSelectedGpu({ index: "", value: "" });
    setSelectedVcpu({ index: "", value: "" });
    setSelectedRam({ index: "", value: "" });
    setSelectedStorage({ index: "", value: "" });
    setSelectedQubrid({ index: "", value: "" });
    setSelectedPricing({ index: "", value: "" });
    setSelectOs(null);
    setSelectedValue("");
    setSelectedGpuMemory({ index: "", value: "" });
    setSlectedRegion({ index: "", value: "" });
    setSelectedPythonVersion({ index: "", value: "" });
    setSelectedOsVersion({ index: "", value: "" });
    setSelectType(event.target.value);
    const url = `/manage/plans_list?family_name=${event.target.value}`;

    try {
      const response = await axiosInstance.get(url);
      const data = response.data;
      console.log(data);
      setSelectTypeRes(data);
      console.log(data?.os_version);
      // const firstKey = Object.keys(data?.os_version || {})[0]; // Get the first key dynamically
      // const pythonVersion = data?.os_version[firstKey]?.python_version;

      // console.log(`Key: ${firstKey}, Python Version: ${pythonVersion}`);
      // if (savedPricing) {
      //     let index = data.basePrice.indexOf(savedPricing);
      //     handleVcpuSliderChange(index)
      // }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          handleSelectTypeOptionChange(event.target.value);
        }
      } else {
        console.error("Error:", error);
      }
    }
  };

  const handleSelectOsOptionChange = (event) => {
    setSelectOs(event.target.value);
  };

  const handleSelectProgrammingLangOptionChange = (event) => {
    setSelectProgrammingLang(event.target.value);
  };

  // const [selectAiPackages, setSelectAiPackages] = useState(null)
  // const handleSelectAiPackagesOptionChange = (event) => {
  //     setSelectAiPackages(event.target.value)
  // }

  const handleSelectInterfaceOptionChange = (event) => {
    setSelectInterface(event.target.value);
  };

  const handleAutoStopChange = (event) => {
    setSelectedAutoStop(event.target.value);
  };

  const gpuCountMarks = gpuCountValues?.map((value, index) => ({
    value: index,
    label: `${value}`,
  }));

  const vcpuMarks = vcpuValues?.map((value, index) => ({
    value: index,
    label: `${value}`,
  }));

  const ramMarks = ramValues?.map((value, index) => ({
    value: index,
    label: `${value}`,
  }));

  const storageMarks = storageValues?.map((value, index) => ({
    value: index,
    label: `${value}`,
  }));

  // Initialize state with the first index and value
  const [selectedGpu, setSelectedGpu] = useState({
    index: 0,
    value: gpuCountValues?.[0],
  });
  const [selectedVcpu, setSelectedVcpu] = useState({
    index: 0,
    value: vcpuValues?.[0],
  });
  const [selectedRam, setSelectedRam] = useState({
    index: 0,
    value: ramValues?.[0],
  });
  const [selectedStorage, setSelectedStorage] = useState({
    index: 0,
    value: storageValues?.[0],
  });
  const [selectedQubrid, setSelectedQubrid] = useState({
    index: 0,
    value: qubridSelection?.[0],
  });
  const [selectedGpuMemory, setSelectedGpuMemory] = useState({
    index: 0,
    value: gpuMemory?.[0],
  });
  const [selectedPricing, setSelectedPricing] = useState({
    index: 0,
    value: osVersion?.[0].hourly_price,
  });
  const [selectedRegion, setSelectedRegion] = useState({
    index: 0,
    value: region?.[0],
  });
  const [selectedOsVerion, setSelectedOsVersion] = useState({
    index: 0,
    value: osVersion?.[0].name,
  });
  console.log(osVersion?.[0].name);

  const [selectedPythonVersion, setSelectedPythonVersion] = useState({
    index: 0,
    value: pythonVersion?.[0].python_version,
  });
  console.log(osVersion);

  useEffect(() => {
    setSelectedGpu({ index: 0, value: gpuCountValues?.[0] });
    setSelectedVcpu({ index: 0, value: vcpuValues?.[0] });
    setSelectedRam({ index: 0, value: ramValues?.[0] });
    setSelectedStorage({ index: 0, value: storageValues?.[0] });
    setSelectedQubrid({ index: 0, value: qubridSelection?.[0] });
    setSelectedGpuMemory({ index: 0, value: gpuMemory?.[0] });
    setSelectedPricing({ index: 0, value: pricing?.[0].hourly_price });
    setSelectedRegion({ index: 0, value: region?.[0] });
    setSelectedPythonVersion({
      index: 0,
      value: pythonVersion?.[0].python_version,
    });
    setSelectedOsVersion({ index: 0, value: osVersion?.[0].name });
  }, [
    gpuCountValues,
    gpuMemory,
    pricing,
    region,
    pythonVersion,
    osVersion,
    qubridSelection,
    ramValues,
    storageValues,
    vcpuValues,
  ]);

  const handleGpuCountSliderChange = (event, newValue) => {
    setSelectedGpu({ index: newValue, value: gpuCountValues[newValue] });
    setSelectedVcpu({ index: newValue, value: vcpuValues[newValue] });
    setSelectedRam({ index: newValue, value: ramValues[newValue] });
    setSelectedStorage({ index: newValue, value: storageValues[newValue] });
    setSelectedQubrid({ index: newValue, value: qubridSelection[newValue] });
    setSelectedGpuMemory({ index: newValue, value: gpuMemory[newValue] });
    setSelectedPricing({
      index: newValue,
      value: pricing?.[newValue].hourly_price,
    });
    setSelectedRegion({ index: newValue, value: region?.[newValue] });
    setSelectedPythonVersion({
      index: newValue,
      value: pythonVersion[newValue].python_version,
    });
    setSelectedOsVersion({ index: newValue, value: osVersion[newValue].name });
  };

  const handleVcpuSliderChange = (event, newValue) => {
    setSelectedVcpu({ index: newValue, value: vcpuValues[newValue] });
    setSelectedGpu({ index: newValue, value: gpuCountValues[newValue] });
    setSelectedRam({ index: newValue, value: ramValues[newValue] });
    setSelectedStorage({ index: newValue, value: storageValues[newValue] });
    setSelectedQubrid({ index: newValue, value: qubridSelection[newValue] });
    setSelectedGpuMemory({ index: newValue, value: gpuMemory[newValue] });
    setSelectedPricing({
      index: newValue,
      value: pricing?.[newValue].hourly_price,
    });
    setSelectedRegion({ index: newValue, value: region?.[newValue] });
    setSelectedPythonVersion({
      index: newValue,
      value: pythonVersion[newValue].python_version,
    });
    setSelectedOsVersion({ index: newValue, value: osVersion[newValue].name });
  };

  const handleRamSliderChange = (event, newValue) => {
    setSelectedRam({ index: newValue, value: ramValues[newValue] });
    setSelectedGpu({ index: newValue, value: gpuCountValues[newValue] });
    setSelectedVcpu({ index: newValue, value: vcpuValues[newValue] });
    setSelectedStorage({ index: newValue, value: storageValues[newValue] });
    setSelectedQubrid({ index: newValue, value: qubridSelection[newValue] });
    setSelectedGpuMemory({ index: newValue, value: gpuMemory[newValue] });
    setSelectedPricing({
      index: newValue,
      value: pricing?.[newValue].hourly_price,
    });
    setSelectedRegion({ index: newValue, value: region?.[newValue] });
    setSelectedPythonVersion({
      index: newValue,
      value: pythonVersion[newValue].python_version,
    });
    setSelectedOsVersion({ index: newValue, value: osVersion[newValue].name });
  };

  const handleStorageSliderChange = (event, newValue) => {
    setSelectedRam({ index: newValue, value: ramValues[newValue] });
    setSelectedGpu({ index: newValue, value: gpuCountValues[newValue] });
    setSelectedVcpu({ index: newValue, value: vcpuValues[newValue] });
    setSelectedStorage({ index: newValue, value: storageValues[newValue] });
    setSelectedQubrid({ index: newValue, value: qubridSelection[newValue] });
    setSelectedGpuMemory({ index: newValue, value: gpuMemory[newValue] });
    setSelectedPricing({
      index: newValue,
      value: pricing?.[newValue].hourly_price,
    });
    setSelectedRegion({ index: newValue, value: region?.[newValue] });
    setSelectedPythonVersion({
      index: newValue,
      value: pythonVersion[newValue].python_version,
    });
    setSelectedOsVersion({ index: newValue, value: osVersion[newValue].name });
  };

  useEffect(() => {
    const osVersions = Object.values(selectTypeRes?.os_version ?? []);
    if (osVersions.length === 1) {
      handleMenuItemClick(osVersions[0]);
      setSelectProgrammingLang("Python");
      setSelectInterface("Jupyter");
    }
  }, [selectTypeRes, handleMenuItemClick]);

  const handleReset = () => {
    setSelectedOption(null);
    setSelectType(null);
    setSelectOs(null);
    setSelectProgrammingLang(null);
    setSelectInterface(null);
    setSelectedRam({ index: "", value: "" });
    setSelectedGpu({ index: "", value: "" });
    setSelectedVcpu({ index: "", value: "" });
    setSelectedStorage({ index: "", value: "" });
    setSelectedQubrid({ index: "", value: "" });
    setSelectedGpuMemory({ index: "", value: "" });
    setSelectedPricing({ index: "", value: "" });
    setSelectedRegion({ index: "", value: "" });
    setSelectedPythonVersion({ index: "", value: "" });
    selectedOsVerion({ index: "", value: "" });
    setSelectedValue(null);
    setSelectedAutoStop("");
  };

  const launchCreateAiCompute = async () => {
    let errorMessage = "";

    if (!selectedOption) {
      errorMessage += "Instance Type, ";
    }
    if (!selectedOsVerion?.value) {
      errorMessage += "Ubuntu Version, ";
    }
    if (!selectedQubrid?.value) {
      errorMessage += "Qubrid Name, ";
    }
    if (!selectedAutoStop) {
      errorMessage += "Features, ";
    }

    if (errorMessage) {
      toast("Please Select following fields: " + errorMessage.slice(0, -2));
      return false;
    }

    setLaunchingInstance(true);

    setTimeout(() => {
      setLaunchingInstance(false);
      navigate("/myInstance");
    }, 5000);

    try {
      const response = await axiosInstance.post(
        `/manage/create_launch-instance`,
        {
          instance_type: selectedOption,
          qubrid_name: selectedQubrid?.value,
          ubuntu_version: selectedOsVerion?.value,
          auto_stop: selectedAutoStop,
        }
      );
      if (response && response.data) {
        window.open(response.data.notebookUrl, "_blank");
        toast(response.data.message);
        // Uncomment if needed:
        // sessionStorage.setItem('jupiterUrl', response.data.notebookUrl);
        // navigate('/jupyter');
      }
    } catch (error) {
      toast(error.response.data.message);
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          launchCreateAiCompute();
        }
      }
    }
  };

  const saveToTemplate = async () => {
    setShowConfirmationModal(false);

    let errorMessage = "";

    if (!selectedOsVerion?.value) {
      errorMessage += "Ubuntu Version, ";
    }
    if (!selectedQubrid?.value) {
      errorMessage += "Qubrid Name, ";
    }
    if (!selectedAutoStop) {
      errorMessage += "Auto Stop, ";
    }

    if (errorMessage) {
      toast("Please check the following fields: " + errorMessage.slice(0, -2));
      return false;
    }

    try {
      const result = await axiosInstance.post(`/manage/save_template`, {
        qubrid_name: selectedQubrid?.value,
        ubuntu_version: selectedOsVerion?.value,
        template_name: templateName ?? "untitled template",
        auto_stop: selectedAutoStop,
      });
      toast(result.data.message);
      setTemplateName("");
      saveTemplateGetApi();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          saveToTemplate();
        }
      } else {
        setTemplateName("");
        toast(error.response.data.message);
        return false;
      }
    }
  };

  const [getSavedTempRes, setGetSavedTempRes] = useState([]);
  const saveTemplateGetApi = async () => {
    const url = `/manage/templates`;
    try {
      const response = await axiosInstance.get(url);
      setGetSavedTempRes(response.data.templates);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          saveTemplateGetApi();
        }
      } else {
        console.error("Error:", error);
      }
    }
  };

  useEffect(() => {
    saveTemplateGetApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteSavedTemp = async (id) => {
    setSavedTempModal(false);
    const url = `/manage/templates/${id}`;
    try {
      const response = await axiosInstance.delete(url);
      console.log(response.data.message);
      saveTemplateGetApi();
      toast(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          deleteSavedTemp(id);
        }
      } else {
        console.error("Error:", error);
      }
    }
  };
  return (
    <>
      <div>
        <div className="flex justify-between py-8">
          <div className="pt-3">
            <span className="text-base font-semibold text-secondry-clr">
              Select infrastructure and a programming environment.
            </span>
          </div>
          <button
            className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]  rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-6 py-3"
            onClick={() => setSavedTempModal(true)}
          >
            Saved Template
          </button>
        </div>
        <div className="flex gap-1">
          <div className="w-full h-full">
            <div className="bg-[#FFFFFF] p-10 rounded-lg shadow">
              <Accordion
                defaultExpanded
                sx={{
                  backgroundColor: "#F8F9FC",
                  boxShadow: "none",
                  borderRadius: "4px",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMore
                      className="text-base font-medium"
                      sx={{ color: "#10001A" }}
                    />
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="text-base font-medium"
                  sx={{ color: "#10001A" }}
                >
                  Select Compute<span className="text-red-700">*</span>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex space-x-3">
                    <div
                      className={`flex w-[152px] h-[51px] p-2 bg-[#FFFFFF] rounded gap-3 cursor-pointer items-center ${
                        selectedOption === "gpu"
                          ? "border-2 border-tab-active-color"
                          : ""
                      }`}
                      onClick={() =>
                        handleOptionChange({ target: { value: "gpu" } })
                      }
                    >
                      <label className="flex items-center gap-3 w-full cursor-pointer">
                        <input
                          type="radio"
                          className="ml-1 text-tab-active-color"
                          name="infra"
                          value="gpu"
                          onChange={handleOptionChange}
                          checked={selectedOption === "gpu"}
                        />
                        <img src={gpu} className="w-7 h-7" alt="jupiter-logo" />
                        <span className="text-xs font-semibold text-secondry-clr">
                          GPU
                        </span>
                      </label>
                    </div>
                    <div
                      className={`flex w-[152px] h-[51px] p-2 bg-[#FFFFFF] rounded gap-3 cursor-pointer items-center ${
                        selectedOption === "cpu"
                          ? "border-2 border-tab-active-color"
                          : ""
                      }`}
                      onClick={() =>
                        handleOptionChange({ target: { value: "cpu" } })
                      }
                    >
                      <label className="flex items-center gap-3 w-full cursor-pointer">
                        <input
                          type="radio"
                          className="ml-1 text-tab-active-color"
                          name="infra"
                          value="cpu"
                          onChange={handleOptionChange}
                          checked={selectedOption === "cpu"}
                        />
                        <img src={cpu} className="w-7 h-7" alt="jupiter-logo" />
                        <span className="text-xs font-semibold text-secondry-clr">
                          Only CPU
                        </span>
                      </label>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                defaultExpanded
                sx={{
                  backgroundColor: "#F8F9FC",
                  boxShadow: "none",
                  borderRadius: "4px",
                  my: 2,
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMore
                      className="text-base font-medium"
                      sx={{ color: "#10001A" }}
                    />
                  }
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className="text-base font-medium"
                  sx={{ color: "#10001A" }}
                >
                  Select GPU/CPU Type<span className="text-red-700">*</span>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="grid grid-cols-3 gap-3 mb-3">
                    {selectComputeRes?.families?.map((item, index) => (
                      <div
                        key={index}
                        className={`flex w-full h-[51px] p-2 bg-[#FFFFFF] items-center rounded gap-3 cursor-pointer ${
                          selectType === item.name
                            ? "border-2 border-tab-active-color"
                            : ""
                        }`}
                        onClick={() =>
                          handleSelectTypeOptionChange({
                            target: { value: item.name },
                          })
                        }
                      >
                        <label className="flex items-center gap-3 w-full cursor-pointer">
                          <input
                            type="radio"
                            className="ml-1 text-tab-active-color"
                            name="typeGpuCpu"
                            value={item.name}
                            onChange={handleSelectTypeOptionChange}
                            checked={selectType === item.name}
                          />
                          <img
                            src={selectedOption === "cpu" ? intel : nvidia}
                            className="w-7 h-7"
                            alt="jupiter-logo"
                          />
                          <span className="text-xs font-semibold text-secondry-clr">
                            {item.name}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                  {selectType !== null && (
                    <div>
                      <div className="flex flex-col w-full">
                        {selectedOption === "gpu" && (
                          <div className="flex justify-between items-center w-[500px]">
                            <div className="w-1/3">
                              <span className="font-medium text-sm text-secondry-clr">
                                GPU Count
                              </span>
                            </div>
                            <div className="w-2/3">
                              <Slider
                                size="mid"
                                defaultValue={0}
                                aria-label="Mid"
                                valueLabelDisplay="auto"
                                step={1}
                                marks={gpuCountMarks}
                                min={0}
                                max={gpuCountValues?.length - 1}
                                valueLabelFormat={(value) =>
                                  gpuCountValues?.[value]
                                }
                                value={selectedGpu.index}
                                onChange={handleGpuCountSliderChange}
                              />
                            </div>
                          </div>
                        )}
                        <div className="flex justify-between items-center w-[500px]">
                          <div className="w-1/3">
                            <span className="font-medium text-sm text-secondry-clr">
                              vCPU Core
                            </span>
                          </div>
                          <div className="w-2/3">
                            <Slider
                              size="mid"
                              defaultValue={0}
                              aria-label="Mid"
                              valueLabelDisplay="auto"
                              step={1}
                              marks={vcpuMarks}
                              min={0}
                              max={vcpuValues?.length - 1}
                              valueLabelFormat={(value) => vcpuValues?.[value]}
                              value={selectedVcpu.index}
                              onChange={handleVcpuSliderChange}
                            />
                          </div>
                        </div>

                        <div className="flex justify-between items-center w-[500px]">
                          <div className="w-1/3">
                            <span className="font-medium text-sm text-secondry-clr">
                              RAM (GB)
                            </span>
                          </div>
                          <div className="w-2/3">
                            <Slider
                              size="mid"
                              defaultValue={0}
                              aria-label="Mid"
                              valueLabelDisplay="auto"
                              step={1}
                              marks={ramMarks}
                              min={0}
                              max={ramValues?.length - 1}
                              valueLabelFormat={(value) =>
                                `${ramValues?.[value]}`
                              }
                              value={selectedRam.index}
                              onChange={handleRamSliderChange}
                            />
                          </div>
                        </div>

                        <div className="flex justify-between items-center w-[500px]">
                          <div className="w-1/3">
                            <span className="font-medium text-sm text-secondry-clr">
                              Default Storage (GB)
                            </span>
                          </div>
                          <div className="w-2/3">
                            <Slider
                              size="mid"
                              defaultValue={0}
                              aria-label="Mid"
                              valueLabelDisplay="auto"
                              step={1}
                              marks={storageMarks}
                              min={0}
                              max={storageValues?.length - 1}
                              valueLabelFormat={(value) =>
                                storageValues?.[value]
                              }
                              value={selectedStorage.index}
                              onChange={handleStorageSliderChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>

              <Accordion
                defaultExpanded
                sx={{
                  backgroundColor: "#F8F9FC",
                  boxShadow: "none",
                  borderRadius: "4px",
                  my: 2,
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMore
                      className="text-base font-medium"
                      sx={{ color: "#10001A" }}
                    />
                  }
                  aria-controls="panel4-content"
                  id="panel4-header"
                  className="text-base font-medium"
                  sx={{ color: "#10001A" }}
                >
                  Select Region
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex gap-3 ">
                    {
                      // slectedRegion?.regions?.map((item, index) => (
                      selectedRegion?.value ? (
                        <div
                          className={`flex w-[160px] h-[51px] p-2 items-center rounded gap-3 bg-gray-200 cursor-not-allowed "border-2 border-tab-active-color ${
                            regionOpted === selectedRegion?.value
                              ? "border-2 border-tab-active-color"
                              : ""
                          }`}
                        >
                          <label className="flex items-center gap-3 w-full cursor-pointer">
                            <input
                              type="radio"
                              className="ml-1 text-tab-active-color"
                              name="regionType"
                              value={selectedRegion?.value} // Ensure this is set correctly
                              // onChange={handleRegions}
                              checked={!!selectedRegion?.value}
                            />
                            <span className="text-xs font-semibold text-secondry-clr">
                              {selectedRegion?.value}
                            </span>
                          </label>
                        </div>
                      ) : (
                        slectedRegion?.regions?.map((item, index) => (
                          <div
                            key={index}
                            className={`flex w-[160px] h-[51px] p-2  bg-gray-200 cursor-not-allowed items-center rounded gap-3 ${
                              regionOpted === item
                                ? "border-2 border-tab-active-color"
                                : ""
                            }`}
                            // onClick={() => handleRegions({ target: { value: item } })}
                          >
                            <label className="flex items-center gap-3 w-full cursor-pointer">
                              <input
                                type="radio"
                                className="ml-1 text-tab-active-color"
                                name="regionType"
                                value={item}
                                // onChange={handleRegions}
                                checked={regionOpted === item}
                              />
                              <span className="text-xs font-semibold text-secondry-clr">
                                {item}
                              </span>
                            </label>
                          </div>
                        ))
                      )
                    }
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                defaultExpanded
                sx={{
                  backgroundColor: "#F8F9FC",
                  boxShadow: "none",
                  borderRadius: "4px",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMore
                      className="text-base font-medium"
                      sx={{ color: "#10001A" }}
                    />
                  }
                  aria-controls="panel3-content"
                  id="panel3-header"
                  className="text-base font-medium"
                  sx={{ color: "#10001A" }}
                >
                  Select OS<span className="text-red-700">*</span>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {Object?.values(selectTypeRes?.os_version ?? [])?.map(
                      (ubuntu) => (
                        <MenuItem
                          key={ubuntu.name}
                          onClick={() => handleMenuItemClick(ubuntu)}
                        >
                          {ubuntu.name}
                        </MenuItem>
                      )
                    )}
                    {selectedOsVerion?.value}
                  </Menu> */}

                  <div className="flex space-x-3">
                    <div
                      className={`flex w-[160px] h-[51px] p-2 bg-gray-200 cursor-not-allowed rounded gap-3 items-center ${
                        selectOs === selectedOsVerion?.value
                          ? "border-2 border-tab-active-color"
                          : ""
                      }`}
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      // onClick={handleClick}
                    >
                      <label className="flex items-center gap-2 w-full cursor-pointer">
                        <input
                          type="radio"
                          className="ml-1 text-tab-active-color"
                          name="os"
                          value={selectedOsVerion?.value}
                          // onChange={handleSelectOsOptionChange}
                          checked={!!selectedOsVerion?.value}
                        />
                        <img
                          src={ubuntu}
                          className="w-7 h-7"
                          alt="jupiter-logo"
                        />
                        <span className="text-xs font-semibold text-secondry-clr">
                          {selectedOsVerion?.value || "Ubuntu 22.04"}
                        </span>
                        {/* <ArrowDropDown /> */}
                      </label>
                    </div>
                    {/* <div
                      className={`flex w-[152px] h-[51px] p-2 cursor-not-allowed bg-gray-200 rounded gap-3 items-center ${
                        selectOs === "Windows"
                          ? "border-2 border-tab-active-color"
                          : ""
                      }`}
                      onClick={() =>
                        handleSelectOsOptionChange({ target: { value: "" } })
                      }
                    >
                      <label className="flex items-center  gap-3 w-full cursor-not-allowed">
                        <input
                          type="radio"
                          className="ml-1 text-tab-active-color"
                          name="os"
                          value="Windows"
                          onChange={handleSelectOsOptionChange}
                          checked={selectOs === "Windows"}
                          disabled
                        />
                        <img
                          src={window}
                          className="w-7 h-7"
                          alt="jupiter-logo"
                        />
                        <span className="text-xs font-semibold text-secondry-clr">
                          Windows
                        </span>
                      </label>
                    </div>
                    <div
                      className={`flex w-[152px] h-[51px] p-2 bg-gray-200 rounded gap-3 cursor-not-allowed items-center ${
                        selectOs === "Cent OS"
                          ? "border-2 border-tab-active-color"
                          : ""
                      }`}
                      onClick={() =>
                        handleSelectOsOptionChange({ target: { value: "" } })
                      }
                    >
                      <label className="flex items-center  gap-3 w-full cursor-not-allowed">
                        <input
                          type="radio"
                          className="ml-1 text-tab-active-color"
                          name="os"
                          value="Cent OS"
                          onChange={handleSelectOsOptionChange}
                          checked={selectOs === "Cent OS"}
                          disabled
                        />
                        <img
                          src={centOs}
                          className="w-7 h-7"
                          alt="jupiter-logo"
                        />
                        <span className="text-xs font-semibold text-secondry-clr">
                          Cent OS
                        </span>
                      </label>
                    </div>
                    <div
                      className={`flex w-[152px] h-[51px] p-2 bg-gray-200 rounded gap-3 cursor-not-allowed items-center ${
                        selectOs === "Fedora"
                          ? "border-2 border-tab-active-color"
                          : ""
                      }`}
                      onClick={() =>
                        handleSelectOsOptionChange({ target: { value: "" } })
                      }
                    >
                      <label className="flex items-center  gap-3 w-full cursor-not-allowed">
                        <input
                          type="radio"
                          className="ml-1 text-tab-active-color"
                          name="os"
                          value="Fedora"
                          onChange={handleSelectOsOptionChange}
                          checked={selectOs === "Fedora"}
                          disabled
                        />
                        <img
                          src={fedora}
                          className="w-7 h-7"
                          alt="jupiter-logo"
                        />
                        <span className="text-xs font-semibold text-secondry-clr">
                          Fedora
                        </span>
                      </label>
                    </div> */}
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                defaultExpanded
                sx={{
                  backgroundColor: "#F8F9FC",
                  boxShadow: "none",
                  borderRadius: "4px",
                  my: 2,
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMore
                      className="text-base font-medium"
                      sx={{ color: "#10001A" }}
                    />
                  }
                  aria-controls="panel4-content"
                  id="panel4-header"
                  className="text-base font-medium"
                  sx={{ color: "#10001A" }}
                >
                  Select Programming Language
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className={`flex w-[160px] h-[51px] p-2  bg-gray-200 cursor-not-allowed items-center rounded gap-3 ${
                      selectProgrammingLang === selectedPythonVersion?.value
                        ? "border-2 border-tab-active-color"
                        : ""
                    }`}
                    onClick={() =>
                      handleSelectProgrammingLangOptionChange({
                        target: { value: "Python" },
                      })
                    }
                  >
                    <label className="flex items-center gap-3 w-full cursor-pointer">
                      <input
                        type="radio"
                        className="ml-1 text-tab-active-color"
                        name="programmingLang"
                        value={selectedPythonVersion?.value}
                        onChange={handleSelectProgrammingLangOptionChange}
                        checked={!!selectedPythonVersion?.value}
                      />
                      <img
                        src={pythonImg}
                        className="w-7 h-7"
                        alt="jupiter-logo"
                      />
                      <span className="text-xs font-semibold text-secondry-clr">
                        {/* {selectTypeRes?.os_version[
                          Object.keys(selectTypeRes?.os_version || {})[0]
                        ]?.python_version || "Python"} */}
                        {selectedPythonVersion?.value || "Python 3.10"}
                      </span>
                    </label>
                  </div>
                </AccordionDetails>
              </Accordion>

              {/* <Accordion sx={{ backgroundColor: '#F8F9FC', boxShadow: 'none', borderRadius: '4px' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMore className='text-base font-medium' sx={{ color: '#10001A' }} />}
                                aria-controls="panel5-content"
                                id="panel5-header"
                                className='text-base font-medium' sx={{ color: '#10001A' }}
                            >
                                Select AI Packages
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='flex gap-3'>
                                    <div
                                        className={`flex w-[200px] h-[51px] p-2 bg-[#FFFFFF] items-center rounded gap-1.5 cursor-pointer ${selectAiPackages === 'Stable Diffusion' ? 'border-2 border-tab-active-color' : ''}`}
                                        onClick={() => handleSelectAiPackagesOptionChange({ target: { value: 'Stable Diffusion' } })}
                                    >
                                        <label className="flex items-center gap-1.5 w-full cursor-pointer">
                                            <input
                                                type='radio'
                                                className='ml-1 text-tab-active-color'
                                                name='aiPackages'
                                                value='Stable Diffusion'
                                                onChange={handleSelectAiPackagesOptionChange}
                                                checked={selectAiPackages === 'Stable Diffusion'}
                                            />
                                            <img src={stableDifusion} className='w-7 h-7' alt='jupiter-logo' />
                                            <span className='text-xs font-semibold text-secondry-clr'>Stable Diffusion</span>
                                            <span><BsInfo className='text-2xl border-1 rounded-full bg-gray-50' /></span>
                                        </label>
                                    </div>
                                    <div
                                        className={`flex w-[200px] h-[51px] p-2 bg-[#FFFFFF] items-center rounded gap-3 cursor-pointer ${selectAiPackages === 'TensorRT' ? 'border-2 border-tab-active-color' : ''}`}
                                        onClick={() => handleSelectAiPackagesOptionChange({ target: { value: 'TensorRT' } })}
                                    >
                                        <label className="flex items-center gap-3 w-full cursor-pointer">
                                            <input
                                                type='radio'
                                                className='ml-1 text-tab-active-color'
                                                name='aiPackages'
                                                value='TensorRT'
                                                onChange={handleSelectAiPackagesOptionChange}
                                                checked={selectAiPackages === 'TensorRT'}
                                            />
                                            <img src={tenserRt} className='w-7 h-7' alt='jupiter-logo' />
                                            <span className='text-xs font-semibold text-secondry-clr'>TensorRT</span>
                                            <span><BsInfo className='text-2xl border-1 rounded-full bg-gray-50' /></span>
                                        </label>
                                    </div>
                                    <div
                                        className={`flex w-[200px] h-[51px] p-2 bg-[#FFFFFF] items-center rounded gap-3 cursor-pointer ${selectAiPackages === 'PyTorch' ? 'border-2 border-tab-active-color' : ''}`}
                                        onClick={() => handleSelectAiPackagesOptionChange({ target: { value: 'PyTorch' } })}
                                    >
                                        <label className="flex items-center gap-3 w-full cursor-pointer">
                                            <input
                                                type='radio'
                                                className='ml-1 text-tab-active-color'
                                                name='aiPackages'
                                                value='PyTorch'
                                                onChange={handleSelectAiPackagesOptionChange}
                                                checked={selectAiPackages === 'PyTorch'}
                                            />
                                            <img src={pyTorch} className='w-7 h-7' alt='jupiter-logo' />
                                            <span className='text-xs font-semibold text-secondry-clr'>PyTorch</span>
                                            <span><BsInfo className='text-2xl border-1 rounded-full bg-gray-50' /></span>
                                        </label>
                                    </div>
                                </div>

                                <div className='flex gap-3 mt-3'>
                                    <div
                                        className={`flex w-[200px] h-[51px] p-2 bg-[#FFFFFF] items-center rounded gap-3 cursor-pointer ${selectAiPackages === 'Tensor Flow' ? 'border-2 border-tab-active-color' : ''}`}
                                        onClick={() => handleSelectAiPackagesOptionChange({ target: { value: 'Tensor Flow' } })}
                                    >
                                        <label className="flex items-center gap-3 w-full cursor-pointer">
                                            <input
                                                type='radio'
                                                className='ml-1 text-tab-active-color'
                                                name='aiPackages'
                                                value='Tensor Flow'
                                                onChange={handleSelectAiPackagesOptionChange}
                                                checked={selectAiPackages === 'Tensor Flow'}
                                            />
                                            <img src={tenserFlow} className='w-7 h-7' alt='jupiter-logo' />
                                            <span className='text-xs font-semibold text-secondry-clr'>Tensor Flow</span>
                                            <span><BsInfo className='text-2xl border-1 rounded-full bg-gray-50' /></span>
                                        </label>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion> */}

              <Accordion
                defaultExpanded
                sx={{
                  backgroundColor: "#F8F9FC",
                  boxShadow: "none",
                  borderRadius: "4px",
                  my: 2,
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMore
                      className="text-base font-medium"
                      sx={{ color: "#10001A" }}
                    />
                  }
                  aria-controls="panel6-content"
                  id="panel6-header"
                  className="text-base font-medium"
                  sx={{ color: "#10001A" }}
                >
                  Select Interface Console
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex gap-3">
                    <div
                      className={`flex w-[152px] h-[51px] p-2 bg-[#FFFFFF] items-center rounded gap-3 cursor-pointer ${
                        selectInterface === "Jupyter"
                          ? "border-2 border-tab-active-color"
                          : ""
                      }`}
                      onClick={() =>
                        handleSelectInterfaceOptionChange({
                          target: { value: "Jupyter" },
                        })
                      }
                    >
                      <label className="flex items-center gap-3 w-full cursor-pointer">
                        <input
                          type="radio"
                          className="ml-1 text-tab-active-color"
                          name="interface"
                          value="Jupyter"
                          onChange={handleSelectInterfaceOptionChange}
                          checked={selectInterface === "Jupyter"}
                        />
                        <img
                          src={jupyter}
                          className="w-7 h-7"
                          alt="jupiter-logo"
                        />
                        <span className="text-xs font-semibold text-secondry-clr">
                          Jupyter
                        </span>
                      </label>
                    </div>
                    <div
                      className={`flex w-[152px] h-[51px] p-2 bg-gray-200 items-center rounded gap-3 cursor-not-allowed ${
                        selectInterface === "VSCode"
                          ? "border-2 border-tab-active-color"
                          : ""
                      }`}
                      onClick={() =>
                        handleSelectInterfaceOptionChange({
                          target: { value: "" },
                        })
                      }
                    >
                      <label className="flex items-center gap-3 w-full cursor-not-allowed">
                        <input
                          type="radio"
                          className="ml-1 text-tab-active-color"
                          name="interface"
                          value="VSCode"
                          onChange={handleSelectInterfaceOptionChange}
                          checked={selectInterface === "VSCode"}
                          disabled
                        />
                        <img
                          src={vsCode}
                          className="w-7 h-7"
                          alt="jupiter-logo"
                        />
                        <span className="text-xs font-semibold text-secondry-clr">
                          VSCode
                        </span>
                      </label>
                    </div>
                    <div
                      className={`flex w-[152px] h-[51px] p-2 bg-gray-200 items-center rounded gap-3 cursor-not-allowed ${
                        selectInterface === "GUI"
                          ? "border-2 border-tab-active-color"
                          : ""
                      }`}
                      onClick={() =>
                        handleSelectInterfaceOptionChange({
                          target: { value: "" },
                        })
                      }
                    >
                      <label className="flex items-center gap-3 w-full cursor-not-allowed">
                        <input
                          type="radio"
                          className="ml-1 text-tab-active-color"
                          name="interface"
                          value="GUI"
                          onChange={handleSelectInterfaceOptionChange}
                          checked={selectInterface === "GUI"}
                          disabled
                        />
                        <img src={gui} className="w-7 h-7" alt="jupiter-logo" />
                        <span className="text-xs font-semibold text-secondry-clr">
                          GUI
                        </span>
                      </label>
                    </div>
                    <div
                      className={`flex w-[152px] h-[51px] p-2 bg-gray-200 items-center rounded gap-3 cursor-not-allowed ${
                        selectInterface === "SSH"
                          ? "border-2 border-tab-active-color"
                          : ""
                      }`}
                      onClick={() =>
                        handleSelectInterfaceOptionChange({
                          target: { value: "" },
                        })
                      }
                    >
                      <label className="flex items-center gap-3 w-full cursor-not-allowed">
                        <input
                          type="radio"
                          className="ml-1 text-tab-active-color"
                          name="interface"
                          value="SSH"
                          onChange={handleSelectInterfaceOptionChange}
                          checked={selectInterface === "SSH"}
                          disabled
                        />
                        <img src={gui} className="w-7 h-7" alt="jupiter-logo" />
                        <span className="text-xs font-semibold text-secondry-clr">
                          SSH
                        </span>
                      </label>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                defaultExpanded
                sx={{
                  backgroundColor: "#F8F9FC",
                  boxShadow: "none",
                  borderRadius: "4px",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMore
                      className="text-base font-medium"
                      sx={{ color: "#10001A" }}
                    />
                  }
                  aria-controls="panel7-content"
                  id="panel7-header"
                  className="text-base font-medium"
                  sx={{ color: "#10001A" }}
                >
                  Features<span className="text-red-700">*</span>
                </AccordionSummary>
                <AccordionDetails>
                  <span className="text-sm font-semibold text-secondry-clr">
                    Auto Stop
                  </span>
                  <div className="flex gap-3">
                    <FormControl
                      variant="standard"
                      sx={{
                        minWidth: 125,
                        height: "47px",
                        background: "#FFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        labelId="auto-stop-select-label"
                        id="auto-stop-select"
                        value={selectedAutoStop}
                        onChange={handleAutoStopChange}
                        label="Auto Stop"
                      >
                        <MenuItem value={160}>Never</MenuItem>
                        <MenuItem value={1}>1 Hour</MenuItem>
                        <MenuItem value={2}>2 Hours</MenuItem>
                        <MenuItem value={4}>4 Hours</MenuItem>
                        <MenuItem value={8}>8 Hours</MenuItem>
                        <MenuItem value={24}>24 Hours</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <div className="w-full bg-[#FFFFFF] rounded-lg shadow flex flex-col justify-between">
            <div className="mb-8 px-10 pt-10">
              <div className="pb-4">
                <p className="text-xl font-semibold text-[#121212]">Review</p>
              </div>

              <div className="flex justify-between">
                <p className="text-base font-medium text-secondry-clr text-opacity-80">
                  Infrastructure Name
                </p>
                <p className="text-base font-semibold text-secondry-clr">
                  {selectedOption?.toUpperCase()}
                </p>
              </div>

              <div className="flex justify-between my-2">
                <p className="text-base font-medium text-secondry-clr text-opacity-80">
                  {selectedOption?.toUpperCase()} Type
                </p>
                <p className="text-base font-semibold text-secondry-clr">
                  {selectType}
                </p>
              </div>
              <div className="border border-gray-100 rounded-md">
                <div className="bg-[#F8F9FC] p-2">
                  <p className="text-sm font-semibold text-secondry-clr">
                    Specification
                  </p>
                </div>
                <div className="p-2">
                  {selectedOption === "gpu" && (
                    <>
                      <div className="flex justify-between">
                        <p className="text-sm font-medium text-secondry-clr text-opacity-60">
                          GPU Count
                        </p>
                        <p className="text-sm font-semibold text-secondry-clr">
                          {selectedGpu?.value}
                        </p>
                      </div>
                      <div className="flex justify-between mt-2">
                        <p className="text-sm font-medium text-secondry-clr text-opacity-60">
                          GPU Memory
                        </p>
                        <p className="text-sm font-semibold text-secondry-clr">
                          {selectedGpuMemory?.value}
                        </p>
                      </div>
                    </>
                  )}
                  <div className="flex justify-between my-2">
                    <p className="text-sm font-medium text-secondry-clr text-opacity-60">
                      CPU Core
                    </p>
                    <p className="text-sm font-semibold text-secondry-clr">
                      {selectedVcpu?.value}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-sm font-medium text-secondry-clr text-opacity-60">
                      RAM
                    </p>
                    <p className="text-sm font-semibold text-secondry-clr">
                      {selectedRam?.value} GB
                    </p>
                  </div>
                  <div className="flex justify-between my-2">
                    <p className="text-sm font-medium text-secondry-clr text-opacity-60">
                      Default Storage
                    </p>
                    <p className="text-sm font-semibold text-secondry-clr">
                      {selectedStorage?.value} GB
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex justify-between p-2 mt-4 border-dashed border-y">
                <p className="text-base font-medium text-secondry-clr text-opacity-80">
                  OS
                </p>
                <p className="text-base font-semibold text-secondry-clr">
                  {selectedOsVerion?.value}
                </p>
              </div>

              <div className="flex justify-between p-2">
                <p className="text-base font-medium text-secondry-clr text-opacity-80">
                  Programming Language
                </p>
                <p className="text-base font-semibold text-secondry-clr">
                  {selectedPythonVersion?.value}
                </p>
              </div>

              <div className="flex justify-between p-2 my-1 border-dashed border-y">
                <p className="text-base font-medium text-secondry-clr text-opacity-80">
                  Interface Console
                </p>
                <p className="text-base font-semibold text-secondry-clr">
                  {selectInterface}
                </p>
              </div>
            </div>

            <div>
              <div className="border-y border-gray-100 p-1">
                <div className="py-4 px-9 ">
                  <p className="text-sm border-dashed border-b text-secondry-clr text-opacity-60 pb-3 bg-[#F8F9FC]">
                    Note: The default token for the Jupyter Notebook will be
                    "Qubrid". You may change it whenever you want.
                  </p>

                  <div className="flex justify-between mt-4 bg-[#F8F9FC]">
                    <p className="text-base font-medium text-secondry-clr text-opacity-60">
                      Price
                    </p>
                    <p className="text-base font-semibold text-secondry-clr">
                      $
                      {selectedPricing?.value ??
                        selectedValue?.hourly_price?.[selectedVcpu?.index] ??
                        0}{" "}
                      / Hour
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-8 px-5 pb-6">
                <div className="flex gap-4 pb-4">
                  <button
                    className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]  rounded-[12px] border border-[#10001ACC] text-lg font-semibold w-full py-3"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                  <button
                    className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]  rounded-[12px] border border-[#10001ACC] text-lg font-semibold w-full py-3"
                    onClick={() => setShowConfirmationModal(true)}
                  >
                    Save to Template
                  </button>
                </div>
                <button
                  className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]  rounded-[12px] border border-[#10001ACC] text-lg font-semibold py-3 w-full"
                  onClick={launchCreateAiCompute}
                  disabled={launchingInstance}
                >
                  {launchingInstance ? "Launching" : " Launch"}
                  {launchingInstance && (
                    <CircularProgress
                      color="secondary"
                      sx={{
                        width: "16px !important",
                        height: "16px !important",
                        marginLeft: "10px",
                      }}
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmationModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto">
              {/*content*/}
              <div className="border-0 w-[500px] rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <div>
                    <h5 className="text-xl font-semibold py-1">
                      Create AI Compute
                    </h5>
                  </div>

                  <button
                    className=" background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowConfirmationModal(false)}
                  >
                    <MdClose className="w-5 h-5" />
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <TextField
                    id="outlined-basic"
                    label="Template Name"
                    variant="outlined"
                    fullWidth
                    value={templateName}
                    onChange={(event) => setTemplateName(event.target.value)}
                  />
                  <span className="py-2.5 text-sm font-normal">
                    It is highly recommended to give your template a name.
                  </span>

                  <div className="mt-5 flex justify-end gap-5">
                    <button
                      type="button"
                      className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]  rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-10 py-3"
                      onClick={() => setShowConfirmationModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1]  text-[#10001ACC]  rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-10 py-3"
                      onClick={saveToTemplate}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {savedTempModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto">
              {/*content*/}
              <div className="border-0  rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <div>
                    <h5 className="text-xl font-semibold py-1">
                      Saved Template
                    </h5>
                  </div>

                  <button
                    className=" background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setSavedTempModal(false)}
                    disabled={savedTempLaunching}
                  >
                    <MdClose className="w-5 h-5" />
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <table className="w-full">
                    <thead className="bg-table-header-bg-color">
                      <tr>
                        <th className="py-4 px-10 text-sm font-semibold text-left">
                          Name
                        </th>
                        <th className="py-4 px-10 text-sm font-semibold text-left">
                          Infrastructure{" "}
                        </th>
                        <th className="py-4 px-10 text-sm font-semibold text-left">
                          Compute Type
                        </th>
                        <th className="py-4 px-10 text-sm font-semibold text-left">
                          Amount
                        </th>
                        <th className="py-4 px-10 text-sm font-semibold text-left">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getSavedTempRes?.map((ele, i) => (
                        <>
                          <tr
                            key={i}
                            className={`${
                              i % 2 === 0 ? "bg-white" : "bg-light-gray-1"
                            } w-full`}
                          >
                            <td className="py-4 px-10 text-sm font-normal">
                              {ele?.template_name}
                            </td>
                            <td className="py-4 px-10 text-sm font-normal">
                              {ele?.qubrid_name}
                            </td>
                            <td className="py-4 px-10 text-sm font-normal">
                              {ele?.family_name}
                            </td>
                            <td className="py-4 px-10 text-sm font-normal">
                              {ele?.hourly_price}
                            </td>
                            <td
                              className="py-4 px-10 text-sm font-normal"
                              disabled={savedTempLaunching}
                            >
                              {savedTempLaunching && (
                                <CircularProgress
                                  color="secondary"
                                  sx={{
                                    width: "16px !important",
                                    height: "16px !important",
                                    marginLeft: "10px",
                                  }}
                                />
                              )}
                              <RocketLaunchOutlined
                                className="cursor-pointer mr-1"
                                onClick={() => getSaveTempValue(ele)}
                              />
                              <DeleteOutlineOutlined
                                className="cursor-pointer"
                                onClick={() => setConfirmDelete(true)}
                              />
                            </td>
                          </tr>
                          {confirmDelete && (
                            <div className="fixed z-10 inset-0 overflow-y-auto">
                              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                <div
                                  className="fixed inset-0 transition-opacity"
                                  aria-hidden="true"
                                >
                                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                </div>
                                <span
                                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                  aria-hidden="true"
                                >
                                  &#8203;
                                </span>
                                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                  <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Are you sure you want to delete?
                                      </h3>
                                    </div>
                                    <div className="mt-5 sm:mt-6 flex justify-center">
                                      <button
                                        type="button"
                                        className="cancel-button"
                                        onClick={() => {
                                          setConfirmDelete(false);
                                          deleteSavedTemp(ele.id);
                                        }}
                                      >
                                        Delete
                                      </button>
                                      <button
                                        type="button"
                                        className="general-button ml-5"
                                        onClick={() => setConfirmDelete(false)}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default CreateAiComputeLeft;
